<template>
  <v-main>
    <v-layout class="pa4">
      <v-flex>
        <v-row>
          <v-col>
            <g-search></g-search>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <g-github-user-list></g-github-user-list>
          </v-col>
        </v-row>
      </v-flex> </v-layout
  ></v-main>
</template>

<script>
import GGithubUserList from "@/components/github/GGithubUserList.vue";
import GSearch from "@/components/github/GSearch.vue";
export default {
  name: "",
  props: [""],
  components: { GSearch, GGithubUserList },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style scoped>
</style>