<template>
  <div>
    <div>
      <v-toolbar dense>
        <v-text-field
          hide-details
          prepend-icon="mdi-magnify"
          single-line
          v-model="keyWord"
          @keyup.enter="getData"
        ></v-text-field>

        <v-btn class="primary" @click="getData"> 搜索 </v-btn>
      </v-toolbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "GSearch",
  props: [""],
  components: {},
  data() {
    return { keyWord: "" };
  },
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    getData() {
      const that = this;
      this.$bus.$emit("updateListData", {
        isFirst: false,
        isLoading: true,
        users: [],
      });
      this.$axios
        .get(`https://api.github.com/search/users?q=${this.keyWord}`)
        .then((response) => {
          that.$bus.$emit("updateListData", {
            isLoading: false,
            users: response.data.items,
          });
        })
        .catch((error) => {
          that.$bus.$emit("updateListData", {
            isLoading: false,
            errMsg: error.message,
            users: [],
          });
        });
    },
  },
};
</script>

<style scoped>
</style>