import { render, staticRenderFns } from "./GGithubUserList.vue?vue&type=template&id=2826316f&scoped=true&"
import script from "./GGithubUserList.vue?vue&type=script&lang=js&"
export * from "./GGithubUserList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2826316f",
  null
  
)

export default component.exports