<template>
  <div>
    <v-card class="d-flex flex-wrap" color="grey lighten-2" flat tile>
      <!--user list-->
      <v-card
        v-show="info.users.length"
        v-for="item in info.users"
        :key="item.login"
        class="pa-2 ma-1"
        outlined
        tile
      >
        <a :href="item.html_url" target="_blank">
          <v-avatar>
            <img :src="item.avatar_url" :alt="item.login" />
          </v-avatar>
        </a>
        {{ item.login }}
      </v-card>
      <!--welcome-->
      <h1 v-show="info.isFirst">Welcome</h1>
      <!--loading....-->
      <h1 v-show="info.isLoading">Loading ....</h1>
      <!--error-->
      <h1 v-show="info.errMsg">{{ info.errMsg }}</h1>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "GGithubUserList",
  props: [""],
  components: {},
  data() {
    return {
      info: {
        isFirst: true,
        isLoading: false,
        errMsg: "",
        users: [],
      },
    };
  },
  mounted() {
    this.$bus.$on("updateListData", (payload) => {
      this.info = { ...this.info, ...payload };
    });
  },
  computed: {},
  watch: {},
  methods: {},
  beforeDestroy() {
    this.$bus.$off("updateListData");
  },
};
</script>

<style lang="scss" scoped>
</style>